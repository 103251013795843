<!-- eslint-disable vue/multi-word-component-names -->
<template> 
  <div class="">
    <ClientOnly>
      <ASideBar
        v-if="$device.isDesktopOrTablet"
        order="2"
        class="z-[3]"
      />
      <ProductSelector v-else />
      <template #fallback>
        <!-- Nothing will be rendered server side -->
      </template>
    </ClientOnly>

    <v-layout 
      class="z-[0] mt-[40px] md:mt-[-15px]"
    >
      <v-main class="">
        <template v-if="error || pending">
          <Skeleton />
        </template>
        <template v-else>
          <v-container
            class=""
            style="max-width:100%;overflow-x:hidden;"
          >
            <div class="sm:mt-[20px] gap-4 sm:mx-3 grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1">
              <div
                v-for="(item,p) in vueFireDocs"
                :key="item.id"
                class="w-full my-1"
              >
                <div 
                  class="card w-auto p-2 h-full m-0"
                >
                  <v-btn
                    icon
                    variant="text"
                    elevation="0"
                    :ripple="false"
                    class="absolute right-5 mb-n12"
                  >
                    <MakeAWishButton
                      :id="item.id"
                      :item-title="item.title"
                      :item-image-src="item.images[0].src"
                      :item-price="item.variants[0].price"
                      :item-ship-cost="item.variants[0].shipCost"
                    />
                  </v-btn>
                  <!-- <NuxtLink
                    :to="'/shop/product/'+item.id"
                    class=""
                  >
                    <NuxtImg
                      :src="item.images[0].src"
                      class="w-full"
                    />
                  </NuxtLink> -->
                  <!-- :hide-delimiters="$device.isDesktopOrTablet?true:false" -->
                  <v-responsive
                    :aspect-ratio="1 / 1"
                    class="border rounded-lg"
                  >
                    <v-carousel
                      show-arrows="hover"
                      height="auto"
                      color="primary"
                      :hide-delimiters="$device.isDesktopOrTablet?true:false"
                      hide-delimiter-background=""
                      class=""
                    >
                      <!-- <template #prev>
                        <v-btn
                          icon
                          color="transparent"
                          elevation="0"
                          @click="imageDecrement(product)"
                        >
                          <v-icon
                            size="large"
                            color="secondary"
                          >
                            mdi-chevron-left
                          </v-icon>
                        </v-btn>
                      </template>
                      <template #next>
                        <v-btn
                          icon
                          color="transparent"
                          elevation="0"
                          @click="imageIncrement(product)"
                        >
                          <v-icon
                            size="large"
                            color="secondary"
                          >
                            mdi-chevron-right
                          </v-icon>
                        </v-btn>
                      </template> -->
                      <v-carousel-item
                        v-for="(img, i) in item.images"
                        :key="i"
                      
                        class=""
                      >
                        <NuxtLink :to="'/shop/product/'+item.id">
                          <NuxtPicture
                            :src="img.src"
                            loading="lazy"
                            :preload="p<5&&i==0?true:false"
                            :alt="img.alt?img.alt:'This is an image of the '+item.title+' product.  If you are hearing this right now.. we assume you are blind.  We are sorry about that.  We hope you enjoy our products, and can assure you they are all very cool/stylish/fashionable/trendy/etc.'"
                            :placeholder="[250,250,50,5]"
                            quality="80"
                            class="w-100 align-center justify-center"
                          />
                        </NuxtLink>
                      
                        <template #placeholder>
                          <div class="d-flex align-center justify-center fill-height">
                            <v-progress-circular
                              indeterminate
                              aria-label="Visual Loading"
                            />
                          </div>
                        </template>
                      </v-carousel-item>
                    </v-carousel>
                  </v-responsive>
                  <div class="flex flex-col gap-1 justify-space-between mt-2 fill-height">
                    <NuxtLink
                      :to="'/shop/product/'+item.id"
                      class=""
                    >
                      <p class="text-center text-lg font-bold text-wrap">
                        {{ item.title }}
                      </p>
                    </NuxtLink>
                    <p class="text-center text-primary font-bold">
                      {{ formatter.format((item.variants[0].price)/100) }}
                    </p>
                    <!-- <p
                      v-if="item.variants[0].shipCost>0"
                      class="text-secondary text-center"
                    >
                      +{{ formatter.format((item.variants[0].shipCost)/100) }} Shipping
                    </p>
                    <p
                      v-else
                      class="text-secondary text-center font-bold"
                    >
                      Free Shipping!
                    </p> -->
                    <div class="w-full mt-2">
                      <ZAddToCartButton
                        :item-id="item.id"
                        :variant-id="item.variants[0].id"
                        class="w-full"
                      />
                    </div>
                  </div> 
                </div>
              </div>
            </div>
            <template v-if="error">
              There was a problem loading more content...
            </template>
            <template v-if="empty">
              <v-alert type="warning">
                No more items!
              </v-alert>
            </template>
          </v-container>
          <div class="flex md:flex-row flex-col align-center justify-center w-full gap-4 mb-2">
            <button
              v-if="prevBtnVisible"
              class="btn btn-wide elevation-4 btn-primary text-primary-content font-bold"
              @click="prevPage"
            >
              Prev Page
            </button>
            <button
              v-if="nextBtnVisible"
              class="btn btn-wide elevation-4 btn-primary text-primary-content font-bold"
              @click="nextPage"
            >
              Next Page
            </button>
          </div>
        </template>
      </v-main>
    </v-layout>
  </div>
</template>


<script setup>
import { usePendingPromises, useFirestore, useCollection } from 'vuefire';
import { query, collection, orderBy, where, limit, limitToLast, startAfter, endBefore } from 'firebase/firestore'
// import { flatten } from 'lodash-es'

// const products = ref([])
// const error = ref(false)

// Really need to convert this to use bundles, and hopefully client-side indexing someday
// const { data:products } = useNuxtData('defaultProducts')

onMounted(async()=>{ //does this need to be async?
  if(startToggle.value=='init' && !pending.value){
    if(!vueFireDocs.value[0]){
      empty.value = true
    } else{
      firstProductId.value = JSON.stringify(vueFireDocs.value[0].id)
    }
  }
})

// const startAfterVal = ref()

// const q = query(
//   collection(useFirestore(),'products'),
//   orderBy('created_at', 'desc'), //these will soon be customizable
//   where('visible','==',true),
//   limit(9),
// )
// const loadEnabled = ref(false)
// const productGroups = ref([])
// const { data:products, pending, error } = useCollection(q, {ssrKey:'productsInitial'})
// if(!pending.value && !error.value){
//   productGroups.value.push(products.value)
//   if(products.value.length<9){
//       // productGroups.value = [products.value]
//       loadEnabled.value = false
//   } 
//   if(products.value.length==9){
//     loadEnabled.value = true
//   }
// }

const docsPerFetch = 16
// const allDocs = ref([])
// const infinite = ref()
const startAfterMe = ref()
const endBeforeMe = ref()

// const countCollection = query(collection(useFirestore(),'products'),where('visible','==',true))
// const productCount = (await getCountFromServer(countCollection)).data().count
// const page = ref(1)
// const pages = ref(Math.ceil(productCount/docsPerFetch))


// const collectionQuery = computed(()=>{
//   return query(
//     collection(useFirestore(),'products'),
//     orderBy('created_at','desc'),
//     where('visible','==',true),
//     startAfter(startAfterMe.value),
//     limit(docsPerFetch)
//   )
// })

const collectionQuery = computed(()=>{
  if(startToggle.value=='init'){
    //initial query
    return query(
      collection(useFirestore(),'products'),
      orderBy('created_at','desc'),
      where('visible','==',true),
      limit(docsPerFetch)
    )
  } else {
    if(startToggle.value=='end'){
      return query(
        collection(useFirestore(),'products'),
        orderBy('created_at','desc'),
        where('visible','==',true),
        endBefore(endBeforeMe.value),
        limitToLast(docsPerFetch)
      )
    }
    if(startToggle.value=='start'){
      return query(
        collection(useFirestore(),'products'),
        orderBy('created_at','desc'),
        where('visible','==',true),
        startAfter(startAfterMe.value),
        limit(docsPerFetch)
      )
    }
  }
})



const startToggle = ref('init')

const firstProductId = ref()
const prevBtnVisible = ref(false)
const nextBtnVisible = ref(false)
const empty = ref(false)

const { data:vueFireDocs,pending,error } = useCollection(collectionQuery,{ssrKey:'visibleProducts'})


if(startToggle.value=='init' && !pending.value){
  if(!vueFireDocs.value[0]){
    empty.value = true
  } else{
    firstProductId.value = JSON.stringify(vueFireDocs.value[0].id)
  }
}



const nextPage = async() => {
  let endBeforeReset = JSON.stringify(vueFireDocs.value.at(-1).created_at)
  startAfterMe.value = vueFireDocs.value.at(-1).created_at
  startToggle.value = 'start'
  window.scrollTo(0,0)
  if(vueFireDocs.value.length==0){
    //nothing found, reset
    empty.value = true
    startAfterMe.value = null
    endBeforeMe.value = JSON.parse(endBeforeReset)
    nextBtnVisible.value = false //not sure it would work but hey worth a try
    startToggle.value = 'end'
  }
}

const firstDocCreatedAt = ref()

watch(vueFireDocs,(newdocs)=>{
  if(firstProductId.value==JSON.stringify(newdocs[0].id)){
    startToggle.value = 'init'
  }
  if(startToggle.value=='init'){
    prevBtnVisible.value=false
    //first page, can't go back further
  } else {
    firstDocCreatedAt.value = newdocs[0].created_at
    prevBtnVisible.value = true
  }
  if(newdocs.length<docsPerFetch){
    empty.value = true
    nextBtnVisible.value = false
  } else{
    empty.value = false
    nextBtnVisible.value = true
  }
},{deep:true})

const prevPage = () => {
  if(vueFireDocs.value[0] && firstProductId.value!=JSON.stringify(vueFireDocs.value[0].id)){
    endBeforeMe.value = firstDocCreatedAt.value
    startToggle.value = 'end'
  }
  
  
  //the below doesn't work, it doesn't wait for vuefiredocs to update with newdocs
  //have to use the new watcher above
  // console.log('firstProdId: '+firstProductId.value)
  // console.log("vueFireDocs[0].id: " + vueFireDocs.value[0].id)
  // if(firstProductId.value==JSON.stringify(vueFireDocs.value[0].id)){
  //   prevBtnVisible.value=false
  //   console.log('just got to the first page')
  // }
  window.scrollTo(0,0)
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});  

// const { data: products, pending, error, promise } = useCollection(q,{ssrKey:'defaultProducts'})


//TODO
//maybe on promise resolve we could parse and pass tags to the sidebar from here?


useServerSeoMeta({
  title: useRuntimeConfig().public.SHOP_NAME ,
  ogTitle: useRuntimeConfig().public.SHOP_NAME,
  description: 'Find something awesome, or work with us to create custom products!',
  ogDescription: 'Ever shop here?  A new US-Based eCommerce site with great prices on dope products.  They even do custom prints on tons of different products!',
  ogImage: 'favicon.png',
  twitterCard: 'summary_large_image',
  ogType:'website',
  ogUrl:'https://'+useRuntimeConfig().public.SITE_DOMAIN
})

onServerPrefetch(() => usePendingPromises()) //Do we really need this?
</script>

<style scoped>
.backimg {
  background: #ffffff url(https://cdn.midjourney.com/285abd20-6660-49fc-a8b4-90ca9a7909ef/0_3.webp) center center/auto repeat content-box;
}
.verytrans {
  background-color: rgb(var(--v-theme-error-lighten-1), 0.75);
}

.littletrans {
  background-color: oklch(var(--a), 0.7);
} 

.backmiddle {
  background: linear-gradient(0deg,  rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, .2) 40%, rgba(255, 255, 255, .3) 100%);
} 

.backdiv{
  height: screen;
  width: 100vw;
  position: fixed;
  top:0px;
  bottom: 0px;
  left:0px;
  right: 0px;
  z-index: 0;
} </style>